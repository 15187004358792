























































































import UpdateCommissionDropdown from "@/components/Webmaster/Commissions/table/UpdateCommissionDropdown.vue";
import CommissionsTableDetail from "@/components/Webmaster/Commissions/table/CommissionsTableDetail.vue";
import CommissionsGroups from "@/components/Webmaster/Commissions/table/CommissionsGroups.vue";
import GroupsSelectDropdown from "@/components/Common/Select/GroupsSelectDropdown.vue";
import TablePlaceholder from "@/components/Common/Table/TablePlaceholder.vue";
import LTable from "@/components/Common/LTable.vue";
import { CommissionsGroupsEnum } from "@core/store/types/admin/commissions/CommissionsGroupsEnum";
import { useCommissions } from "@/stores/webmaster/commissions/comissionsStore";
import { ref, computed, ComponentPublicInstance } from "@vue/composition-api";
import useDataTable from "@/mixins/table/useTableData";
import { useWait } from "@/stores/common/waitStore";
import { storeToRefs } from "pinia";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const store = useCommissions();
  const {
    data,
    onlyFilters,
    groups
  } = storeToRefs(store);
  const isLoading = useWait(store, "GET_COMMISSIONS");
  const table = ref<ComponentPublicInstance<any> | null>(null);
  const detailTable = ref<ComponentPublicInstance<any> | null>(null);
  const group = computed(() => groups.value[0].toLowerCase());
  const allGroups = computed(() => {
    return Object.values(CommissionsGroupsEnum).filter(item => !groups.value.includes(item)).map(el => el.toLowerCase());
  });
  const isSubAccount = computed(() => groups.value[0] === CommissionsGroupsEnum.SUB_ACCOUNT);
  const {
    currentGroups,
    countColumns,
    tableLabels,
    totalRows,
    reopenDetails,
    changeCurrentGroup
  } = useDataTable(store, table);
  async function update() {
    await store.GET_COMMISSIONS();
  }
  return {
    data,
    onlyFilters,
    groups,
    isLoading,
    table,
    detailTable,
    group,
    allGroups,
    isSubAccount,
    currentGroups,
    countColumns,
    tableLabels,
    totalRows,
    reopenDetails,
    changeCurrentGroup,
    update
  };
};
__sfc_main.components = Object.assign({
  TablePlaceholder,
  LTable,
  GroupsSelectDropdown,
  CommissionsGroups,
  UpdateCommissionDropdown,
  CommissionsTableDetail
}, __sfc_main.components);
export default __sfc_main;
