<template>
    <b-dropdown
        ref="dropdown"
        :can-close="false"
        :class="{ 'invisible': !isVisible }"
        :trap-focus="!isVisible"
        :triggers="[]"
        append-to-body
        position="is-bottom-left"
        @active-change="updateIsActive">
        <div
            slot="trigger"
            @input="changeHandler"
            @keyup.enter="keyup(confirm)"
            @keyup.esc="keyup(cancel)">
            <slot
                :isActive="isActive"
                :updateNewValue="updateNewValue"
                :value="newValue"></slot>
        </div>
        <div
            v-if="isVisible"
            :style="outerScopeCSS"
            class="card">
            <slot name="card">
                <div class="columns is-multiline">
                    <div class="column pb-0 is-12">
                        <slot
                            :newValue="newValue"
                            :value="value"
                            name="content">
                            <i18n path="common.components.confirmationTooltip.content">
                                <template #value>
                                    <strong>
                                        <slot
                                            :value="value"
                                            name="value">
                                            {{ formatEmptyString(defaultFormatter(value)) }}
                                        </slot>
                                    </strong>
                                </template>
                                <template #newValue>
                                    <strong>
                                        <slot
                                            :newValue="newValue"
                                            name="newValue">
                                            {{ formatEmptyString(defaultFormatter(newValue)) }}
                                        </slot>
                                    </strong>
                                </template>
                            </i18n>
                        </slot>
                    </div>
                    <div class="column is-12 has-text-centered">
                        <slot name="buttons">
                            <div class="columns is-variable is-1">
                                <div class="column">
                                    <b-button
                                        expanded
                                        size="is-small"
                                        type="is-success is-light"
                                        @click="confirm">
                                        {{ $t(`common.components.confirmationTooltip.buttons.confirm`) }}
                                    </b-button>
                                </div>
                                <div class="column">
                                    <b-button
                                        expanded
                                        size="is-small"
                                        type="is-danger is-light"
                                        @click="cancel">
                                        {{ $t(`common.components.confirmationTooltip.buttons.cancel`) }}
                                    </b-button>
                                </div>
                            </div>
                        </slot>
                    </div>
                </div>
            </slot>
        </div>
    </b-dropdown>
</template>

<!--suppress EqualityComparisonWithCoercionJS -->
<script>
  import styles from "@/scss/_theme-default.scss";
  import { formatEmptyString, defaultFormatter } from "@core/filters";
  
  export default {
    name: "ConfirmationTooltip",
    props: {
      /**
       * Если required true, то компонент не должен принимать пустые значения.
       * @return {Boolean}
       */
      required: {
        type: Boolean,
        default: false
      },
      value: {
        type: [String, Number],
        default: null
      }
    },

    created () {
      this.setInitialValue();
    },

    data () {
      return {
        newValue: "",
        isActive: false,
        isValid: true
      };
    },

    computed: {
      outerScopeCSS () {
        return {
          fontSize: styles.sizeNormal,
          minWidth: "160px"
        };
      },

      isVisible () {
        if (!this.isValid) {
          return false;
        }

        // eslint-disable-next-line eqeqeq
        const isChanged = this.value != this.newValue;

        if (this.required) {
          return this.newValue === "" ? false : isChanged;
        } else {
          return isChanged;
        }
      }
    },

    methods: {
      keyup (action) {
        if (this.isVisible) {
          action();
        }
      },

      changeHandler (e) {
        this.isValid = e.target.checkValidity();
      },

      confirm () {
        this.$emit("input", this.newValue);
        this.$emit("change", this.newValue);
        this.toggleDropdown();
      },

      cancel () {
        this.setInitialValue();
        this.toggleDropdown();
      },

      updateNewValue (value) {
        // eslint-disable-next-line eqeqeq
        if (!this.isActive && this.newValue != value) {
          this.toggleDropdown();
        }

        if (value === "") {
          this.newValue = null;
        } else {
          this.newValue = value;
        }
      },

      toggleDropdown () {
        this.$refs.dropdown.toggle();
      },

      updateIsActive (value) {
        this.isActive = value;
      },

      setInitialValue () {
        this.newValue = this.value;
      },

      formatEmptyString,
      defaultFormatter
    },

    watch: {
      value (value) {
        this.newValue = value;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .card {
    padding: 12px 16px;
  }

  .invisible {
    ::v-deep {
      .dropdown-content {
        box-shadow: none;
      }
    }
  }
  
  .dropdown {
    width: 100%;
  }
  
  ::v-deep {
    .dropdown-trigger {
      width: 100%;
    }
    
    .dropdown-menu {
      max-width: 400px;
      min-width: 200px !important;
      text-align: left !important;
      font-size: $size-normal;
      word-wrap: break-word;
      z-index: 9999;
    }

    .dropdown-content {
      padding: 0 !important;
    }
  }

</style>
