





































































































































































import UpdateCommissionDropdown from "@/components/Webmaster/Commissions/table/UpdateCommissionDropdown.vue";
import CommissionsGroups from "@/components/Webmaster/Commissions/table/CommissionsGroups.vue";
import GroupsSelectDropdown from "@/components/Common/Select/GroupsSelectDropdown.vue";
import SubAccountOffers from "@/components/Webmaster/Select/SubAccountOffers.vue";
import LTable from "@/components/Common/LTable.vue";
import { ComponentPublicInstance, computed, onBeforeUnmount, reactive, ref, watch } from "@vue/composition-api";
import { CommissionsFilters, CommissionsItem } from "@core/store/types/admin/commissions/commissions";
import { CommissionsGroupsEnum } from "@core/store/types/admin/commissions/CommissionsGroupsEnum";
import { useSubAccountOffers } from "@/stores/webmaster/commissions/commissionsSubStore";
import { withDefaults, defineProps } from "@vue/runtime-dom";
import useDataTable from "@/mixins/table/useTableData";
import { useWait } from "@/stores/common/waitStore";
import { formatCurrency } from "@/filters";
import { storeToRefs } from "pinia";
import { Money } from "@core/store/types/common";
interface Props {
  rowData: CommissionsItem & {
    [key: string]: CommissionsItem[] & CommissionsItem & Date;
  };
  group: string;
  parentGroup: string;
  uniqueKey: string;
  parentFilters: Partial<CommissionsFilters>;
  parentCountColumns: number;
  initialGroups: (CommissionsGroupsEnum | any)[];
  initialData: (CommissionsItem | any)[];
  parentTotalRows: number;
  parentRowIndex: number;
  parentColumns: {
    label: string;
    value: string;
  }[];
}
type CurrentFilters = {
  [key: string]: string[];
};
import { defineComponent } from "@vue/composition-api";
const __sfc_main = defineComponent({
  name: "CommissionsTableDetail" // For recursive components, make sure to provide the "name" option.
});
__sfc_main.props = {
  rowData: {
    key: "rowData",
    required: true,
    type: Object
  },
  group: {
    key: "group",
    required: true,
    type: String
  },
  parentGroup: {
    key: "parentGroup",
    required: true,
    type: String
  },
  uniqueKey: {
    key: "uniqueKey",
    required: true,
    type: String
  },
  parentFilters: {
    key: "parentFilters",
    required: false,
    type: Object,
    default: () => ({})
  },
  parentCountColumns: {
    key: "parentCountColumns",
    required: false,
    type: Number,
    default: 1
  },
  initialGroups: {
    key: "initialGroups",
    required: false,
    type: Array,
    default: () => []
  },
  initialData: {
    key: "initialData",
    required: false,
    type: Array,
    default: () => []
  },
  parentTotalRows: {
    key: "parentTotalRows",
    required: false,
    type: Number,
    default: 0
  },
  parentRowIndex: {
    key: "parentRowIndex",
    required: false,
    type: Number,
    default: 0
  },
  parentColumns: {
    key: "parentColumns",
    required: true,
    type: Array
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;

  // const parentGroup = props.parentGroup.toLowerCase();

  const table = ref<ComponentPublicInstance<any> | null>(null);
  const currentFilters = reactive<Partial<CurrentFilters>>({});
  const subStore = useSubAccountOffers(props.uniqueKey);
  const {
    data,
    onlyFilters,
    filters,
    groups
  } = storeToRefs(subStore);
  const isLoadingTable = useWait(subStore, "GET_SUB_ACCOUNT_OFFERS");
  const isLoadingPagination = useWait(subStore, "GET_SUB_ACCOUNT_OFFERS_WITH_PAGINATION");
  const dataItems = computed(() => data.value?.items ?? []);
  const dataCount = computed(() => data.value?.count ?? 0);
  const subAccountId = props.rowData.subAccount.id;
  const subAccountIntId = props.rowData.subAccount.intId;
  // const shouldGroup = Object.keys(filters.value).findIndex(item => item.includes(parentGroup)) !== -1;
  // const filterKey = shouldGroup ? `${ parentGroup }Id` : parentGroup;

  // if (shouldGroup) {
  //   if (Array.isArray(props.rowData[parentGroup])) {
  //     currentFilters[filterKey] = props.rowData[parentGroup].map(({ id }: { id: string }) => id);
  //   } else {
  //     currentFilters[filterKey] = [props.rowData[parentGroup].id];
  //   }
  // }

  subStore.UPDATE_FILTERS({
    ...props.parentFilters,
    groups: [props.group.toUpperCase() as CommissionsGroupsEnum, ...props.initialGroups],
    ...currentFilters
  });
  update();
  const allGroups = computed(() => {
    return Object.values(CommissionsGroupsEnum).filter(item => !groups.value.includes(item)).map(el => el.toLowerCase());
  });
  const {
    currentGroups,
    countColumns,
    tableLabels,
    totalRows,
    reopenDetails,
    changeCurrentGroup
  } = useDataTable(subStore, table);
  function subRate({
    rate,
    commission
  }: {
    rate: Money;
    commission: Money;
  }): number {
    const offerCommission = props.initialData[0].commission?.find((el: Money) => el.currency === rate.currency);
    const subRate = rate.value - Number(commission?.value ?? offerCommission?.value ?? 0);
    return subRate >= 0 ? subRate : 0;
  }
  async function update() {
    await subStore.GET_SUB_ACCOUNT_OFFERS([subAccountId]);
  }
  async function updateCurrentPage() {
    await subStore.GET_SUB_ACCOUNT_OFFERS_WITH_PAGINATION([subAccountId]);
  }
  watch(() => filters.value.offerOfSubAccount, () => {
    update();
  });
  onBeforeUnmount(() => {
    subStore.$reset();
  });
  return {
    formatCurrency,
    props,
    table,
    data,
    onlyFilters,
    filters,
    groups,
    isLoadingTable,
    isLoadingPagination,
    dataItems,
    dataCount,
    subAccountId,
    subAccountIntId,
    allGroups,
    currentGroups,
    countColumns,
    tableLabels,
    totalRows,
    reopenDetails,
    changeCurrentGroup,
    subRate,
    updateCurrentPage
  };
};
__sfc_main.components = Object.assign({
  LTable,
  SubAccountOffers,
  GroupsSelectDropdown,
  CommissionsGroups,
  UpdateCommissionDropdown
}, __sfc_main.components);
export default __sfc_main;
